/**
 * WITH-FANCY-OL
 *
 * This is a wrapper component for <ol> tags that displays large teal numbers
 * for the child <li> tags
 */

.with-fancy-ol ol {
  counter-reset: fancy-ol-counter;
  @apply list-none;
  @apply m-0;
  @apply p-0;
}
.with-fancy-ol ol li {
  counter-increment: fancy-ol-counter;
  @apply mb-4;
}
.with-fancy-ol ol li::before {
  content: counter(fancy-ol-counter);
  @apply font-semibold;
  @apply text-4xl;
  @apply mr-2;
  @apply leading-none;
  @apply text-teal-light;
}
