/**
 * NAVIGATION
 *
 * Styles required by ../../partials/navigation.hbs
 */

.nav {
  @apply bg-blue-darkest;
  @apply fixed;
  @apply flex;
  @apply flex-col;
  @apply h-full;
  @apply inset-y-0;
  @apply justify-between;
  @apply overflow-y-auto;
  @apply right-0;
  @apply text-teal-light;
  @apply z-20;
  @apply w-56;
}

.nav-button,
.back-button {
  --webkit-backdrop-filter: blur(3px);
  backdrop-filter: blur(3px);
}

.nav-button {
  @apply block;
  @apply cursor-pointer;
  @apply duration-300;
  @apply fixed;
  @apply h-12;
  @apply mr-1;
  @apply mt-5;
  @apply p-4;
  @apply right-0;
  @apply rounded-full;
  @apply text-teal-default;
  @apply transition-colors;
  @apply w-12;
  @apply z-20;
  @apply bg-white;
  @apply bg-opacity-75;
}
.nav-button:focus {
  @apply outline-none;
}
.nav-button:hover {
  @apply text-teal-dark;
}
.page-with-gradient:not(.is-preview) .nav-button {
  @apply bg-blue-lightest-tint-15;
  @apply bg-opacity-75;
}

.back-button {
  @apply fixed;
  @apply top-0;
  @apply left-0;
  @apply z-20;
  @apply block;
  @apply w-12;
  @apply h-12;
  @apply p-4;
  @apply mt-5;
  @apply ml-1;
  @apply rounded-full;
  @apply text-teal-default;
  @apply transition-colors;
  @apply duration-300;
  @apply bg-opacity-75;
  @apply bg-white;
  @apply bg-opacity-75;
}
.back-button:hover {
  @apply text-teal-dark;
}
.back-button:focus {
  @apply outline-none;
  @apply shadow-outline;
}

.nav-button ~ .nav,
.nav-button ~ .back-button,
.nav-button ~ .page {
  @apply transition;
  @apply transform;
  @apply duration-300;
}

.page-with-gradient:not(.is-preview) .back-button {
  @apply bg-teal-lightest-tint-15;
  @apply bg-opacity-75;
}

.nav-button ~ .nav {
  @apply translate-x-56;
}
.nav-button ~ .nav:focus-within,
.nav-button:focus ~ .nav {
  @apply translate-x-0;
}

.nav-button:focus ~ .page,
.nav-button:focus ~ .back-button,
.nav-button ~ .nav:focus-within ~ .page,
.nav-button ~ .nav:focus-within ~ .back-button {
  @apply -translate-x-56;
}

@screen xl {
  .nav-button {
    @apply invisible;
  }

  .nav,
  .page,
  .back-button {
    transform: translate(0) !important; /* do NOT move anything */
  }

  .page {
    @apply mr-56;
  }
}
