/**
 * TEXT-SKELETON
 */

.text-skeleton {
  @apply relative;
  @apply inline-block;
}

.text-skeleton::before {
  @apply invisible;
  content: ".";
}

.text-skeleton::after {
  @apply absolute;
  @apply bg-current;
  @apply block;
  @apply inset-0;
  @apply rounded-full;
  margin: 0.2em 0;
  animation: text-skeleton-anim 1.5s infinite;
  content: "";
}

@keyframes text-skeleton-anim {
  0% {
    opacity: 0.5;
  }

  50% {
    opacity: 0.25;
  }

  100% {
    opacity: 0.5;
  }
}
