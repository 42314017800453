/**
 * SHOW-NEXT-ON-FOCUS
 *
 * This component doesn't work on <button>s cross-browser,
 * use e.g. span[role="button"][tabindex=0]
 */

.show-next-on-focus + * {
  opacity: 0;
  transform: translateY(-0.625rem);
  transition: opacity 200ms, visibility 200ms, transform 200ms;
  visibility: hidden;
}

.show-next-on-focus + :focus-within,
.show-next-on-focus:focus + * {
  opacity: 1;
  transform: translateY(0);
  visibility: visible;
}
