/**
 * LANGUAGE RECOMMENDATION
 *
 * Styles required by ../../partials/language-recommendation.hbs
 */

.language-recommendation {
  @apply fixed;
  @apply inset-x-0;
  @apply top-0;
  @apply z-40;
  @apply flex;
  @apply justify-start;
  @apply mt-16;
  @apply transition-transform;
  @apply transform;
  @apply translate-x-0;
  @apply duration-500;
}

.language-recommendation[hidden] {
  @apply -translate-x-full;
  display: flex !important; /* force NOT to be hidden */
}

.language-recommendation-wrapper {
  @apply bg-white;
  @apply rounded-r-full;
  @apply shadow-glow-blue;
  @apply pl-6;
  @apply pr-5;
  @apply py-3;
  @apply flex;
  @apply items-center;
}

.language-recommendation-info {
  @apply text-blue-darkest;
  @apply whitespace-no-wrap;
  @apply rounded;
  @apply p-2;
  @apply text-lg;
}
.language-recommendation-info:focus {
  @apply outline-none;
  @apply shadow-outline;
}
.language-recommendation-info u {
  @apply text-teal-default;
}

.language-recommendation-close {
  @apply text-teal-default;
  @apply block;
  @apply w-12;
  @apply h-12;
  @apply p-3;
  @apply rounded-full;
}
.language-recommendation-close:focus {
  outline: 0 !important;
  @apply shadow-outline;
}

