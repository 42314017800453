/**
 * FOOTER LINK
 */

.footer-link {
  @apply inline-block;
  @apply px-2;
  @apply py-2;
  @apply rounded;
  @apply transition-colors;
  @apply duration-300;
}

.footer-link:hover {
  @apply text-white;
}

.footer-link:focus {
  @apply outline-none;
  @apply shadow-outline;
}
