/**
 * HR-GRADIENT
 *
 * A teal gradient that fades out at the edges. Can be applied to <hr> tags.
 */

.hr-gradient {
  @apply max-w-2xl;
  @apply border-none;
  @apply mx-auto;
  @apply h-px;
  background: linear-gradient(
    90deg,
    color(theme("colors.teal-light") alpha(0%)) 0% 5%,
    color(theme("colors.teal-light") alpha(75%)) 45% 55%,
    color(theme("colors.teal-light") alpha(0%)) 95% 100%
  );
}

