/*******************************************************************************
 * IMAGES (responsive, especially for Elm)
 *
 * Usage:
 * ======
 *   div [class "img img-plant-trees"] []
 *
 * The container takes up the full width available and adjusts its height
 * automatically. Child nodes won't be visible!
 *
 * Q: Why don't we just use these images via: `img [src "CleanOcean.svg"] []` ?
 * A: Because Parcel doesn't find images referenced in Elm code and therefore
 *    is not able to produce the final files with hashed filenames.
 */

.img {
  @apply block;
  @apply w-full;
  @apply h-0;
  @apply bg-no-repeat;
  @apply overflow-hidden;
}

.img-clean-ocean {
  background-image: url(/images/CleanOcean.svg);
  padding-top: calc(100% / 200 * 200);
}

.img-plant-trees {
  background-image: url(/images/PlantTrees.svg);
  padding-top: calc(100% / 200 * 200);
}

.img-protect-wildlife {
  background-image: url(/images/ProtectWildlife.svg);
  padding-top: calc(100% / 200 * 200);
}

.img-thanku {
  background-image: url(/images/ThankU.svg);
  padding-top: calc(100% / 200 * 200);
}

.img-donate {
  background-image: url(/images/donate.svg);
  padding-top: calc(100% / 489 * 401);
}

.img-mail-sent {
  background-image: url(/images/mail-sent.svg);
  padding-top: calc(100% / 381 * 376);
}

.img-not-found {
  background-image: url(/images/not-found.svg);
  padding-top: calc(100% / 553 * 370);
}

.img-question-mark {
  background-image: url(/images/question-mark.svg);
  padding-top: calc(100% / 461 * 470);
}

.img-send-love {
  background-image: url(/images/send-love.svg);
  padding-top: calc(100% / 4624 * 3799);
}

.img-americanexpress {
  background-image: url(/images/americanexpress.svg);
  padding-top: calc(100% / 84 * 84);
}

.img-paypal {
  background-image: url(/images/paypal.svg);
  padding-top: calc(100% / 84 * 84);
}

.img-applepay {
  background-image: url(/images/applepay.svg);
  padding-top: calc(100% / 84 * 84);
}

.img-googlepay {
  background-image: url(/images/googlepay.svg);
  padding-top: calc(100% / 84 * 84);
}

.img-mastercard {
  background-image: url(/images/mastercard.svg);
  padding-top: calc(100% / 84 * 84);
}

.img-visa {
  background-image: url(/images/visa.svg);
  padding-top: calc(100% / 84 * 84);
}

.img-santas-hat {
  background-image: url(/images/santas-hat.svg);
  padding-top: calc(100% / 330 * 350);
}

.img-gravatar-logo {
  background-image: url(/images/gravatar-logo.svg);
  padding-top: calc(100% / 146 * 27);
}

.img-ambassador-badge {
  background-image: url(/images/ambassador-badge.svg);
  padding-top: calc(100% / 500 * 430);
}

.bg-easter {
  background: url(/images/bg-easter.png) bottom center / auto 2.5rem repeat-x;
}

.bg-woman-with-phone {
  background: url(/images/woman-with-phone.svg) 9.75rem 0.5rem no-repeat;
}
