/**
 * BTN-PRIMARY, BTN-SECONDARY, BTN-TERTIARY
 *
 * Buttons are available in 3 variants:
 * - primary   : teal background, white text
 * - secondary : thin teal border, transparent background, teal text
 * - tertiary  : like secondary, but w/o border
 */

.btn,
.btn-primary,
.btn-secondary,
.btn-tertiary {
  @apply inline-block;
  @apply border;
  @apply px-10;
  @apply py-3;
  @apply text-center;
  @apply rounded-full;
  @apply font-semibold;
  @apply transition-colors;
  @apply duration-300;
  @apply text-base;
}
.btn.btn-small,
.btn-primary.btn-small,
.btn-secondary.btn-small,
.btn-tertiary.btn-small {
  @apply px-8;
  @apply py-2;
  @apply text-sm;
}

.btn-primary {
  @apply text-white;
  @apply bg-teal-default;
  @apply border-teal-default;
}
.btn-tertiary {
  @apply border-0;
}
.btn-secondary,
.btn-tertiary {
  @apply text-teal-default;
  @apply border-teal-light;
}

.btn:focus,
.btn-primary:focus,
.btn-secondary:focus,
.btn-tertiary:focus {
  @apply outline-none;
  @apply shadow-outline;
}

.btn-primary:not([disabled]):not(.disabled):hover {
  @apply bg-teal-dark;
  @apply border-teal-dark;
}
.btn-primary.disabled,
.btn-primary[disabled] {
  @apply bg-teal-light;
  @apply border-teal-light;
  @apply cursor-default;
}

.btn-secondary:not([disabled]):not(.disabled):hover,
.btn-tertiary:not([disabled]):not(.disabled):hover {
  @apply text-teal-dark;
}
.btn-secondary.disabled,
.btn-secondary[disabled],
.btn-tertiary.disabled,
.btn-tertiary[disabled] {
  @apply text-teal-light;
  @apply cursor-default;
}
