/**
 * ICON-BTN-PRIMARY, ICON-BTN-SECONDARY, ICON-BTN-TERTIARY
 *
 * Buttons are available in 3 variants:
 * - primary   : teal background, white text (2 units bigger than the others)
 * - secondary : thin teal border, transparent background, teal text
 * - tertiary  : thin orange border, transparent background, orange text
 */

.icon-btn-primary,
.icon-btn-secondary,
.icon-btn-tertiary {
  @apply border;
  @apply flex-shrink-0;
  @apply inline-flex;
  @apply items-center;
  @apply justify-center;
  @apply p-2;
  @apply rounded-full;
  @apply transition-colors;
  @apply duration-300;
}

.icon-btn-primary {
  @apply w-12;
  @apply h-12;
  @apply bg-teal-default;
  @apply border-teal-default;
  @apply text-white;
  @apply p-3;
}
.icon-btn-primary:not([disabled]):not(.disabled):hover {
  @apply bg-teal-dark;
  @apply border-teal-dark;
}
.icon-btn-primary:not([disabled]):not(.disabled):focus {
  @apply outline-none;
  @apply shadow-outline;
}
.icon-btn-primary.disabled,
.icon-btn-primary[disabled] {
  @apply bg-teal-light;
  @apply border-teal-light;
  @apply cursor-default;
}

.icon-btn-secondary {
  @apply w-10;
  @apply h-10;
  @apply border-teal-light;
  @apply text-teal-default;
}
.icon-btn-secondary:not([disabled]):not(.disabled):hover {
  @apply border-teal-default;
  @apply text-teal-dark;
}
.icon-btn-secondary:not([disabled]):not(.disabled):focus {
  @apply outline-none;
  @apply shadow-outline;
}
.icon-btn-secondary.disabled,
.icon-btn-secondary[disabled] {
  @apply bg-teal-light;
  @apply text-teal-light;
  @apply cursor-default;
}

.icon-btn-tertiary {
  @apply w-10;
  @apply h-10;
  @apply border-orange-light;
  @apply text-orange-dark;
}
.icon-btn-tertiary:not([disabled]):not(.disabled):hover {
  @apply border-orange-default;
  @apply text-orange-dark;
}
.icon-btn-tertiary:not([disabled]):not(.disabled):focus {
  @apply outline-none;
  @apply shadow-outline;
}
.icon-btn-tertiary.disabled,
.icon-btn-tertiary[disabled] {
  @apply border-orange-light;
  @apply text-orange-light;
  @apply cursor-default;
}

