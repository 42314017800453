/**
 * SPEECH-BUBBLE
 *
 * The speech bubble pointer is located in the bottom left corner
 */

.speech-bubble {
  @apply relative;
  @apply bg-white;
  @apply px-5;
  @apply py-3;
  @apply rounded-xl;
  @apply shadow-glow-teal;
  @apply flex;
  @apply items-center;
  min-height: 4rem;
}

/**
 * markdown markup uses vertical margins, so we need to eliminate those
 * in the speech bubble on top and bottom
 */
.speech-bubble .markdown.markdown-elm-inline {
  @apply -my-3;
}
/* re-reset from ./markdown.css (!) */
.speech-bubble .markdown.markdown-elm-inline p {
  @apply my-3;
}

.speech-bubble::after {
  @apply absolute;
  @apply left-0;
  @apply w-0;
  @apply h-0;
  bottom: 1.5rem;
  left: -0.975rem; /* normally -1rem, but sometimes theres a little gap */
  content: "";
  border: 0.5rem solid transparent;
  border-right-color: white;
  border-right-width: 1rem;
  border-left: 0;
  transform: skewY(-26.5deg);
}
