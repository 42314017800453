/**
 * WHEEL-SELECTOR
 */

.wheel-selector {
  @apply relative;
}

.wheel-selector--buttons-right {
  @apply ml-2;
}
.wheel-selector--buttons-left {
  @apply mr-2;
}

.wheel-selector-button-up,
.wheel-selector-button-down {
  @apply w-12;
  @apply h-12;
  @apply p-4;
  @apply transition-colors;
  @apply duration-300;
  @apply absolute;
  @apply block;
  @apply text-teal-light;
}
.wheel-selector-button-up {
  @apply top-0;
}
.wheel-selector-button-down {
  @apply bottom-0;
}
.wheel-selector-button-up:focus,
.wheel-selector-button-down:focus {
  @apply outline-none;
}
.wheel-selector--buttons-right .wheel-selector-button-up,
.wheel-selector--buttons-right .wheel-selector-button-down {
  @apply right-0;
}
.wheel-selector--buttons-left .wheel-selector-button-up,
.wheel-selector--buttons-left .wheel-selector-button-down {
  @apply left-0;
}
.wheel-selector-button-up:not([disabled]):hover,
.wheel-selector-button-down:not([disabled]):hover {
  @apply text-teal-default;
}

.wheel-selector-item-container {
  @apply relative;
  @apply flex;
  @apply justify-center;
  @apply items-center;
  @apply overflow-hidden;
  @apply z-0;
  @apply rounded-full;
  @apply bg-white;
  @apply w-24;
  @apply h-24;
  @apply shadow-glow-teal;
}
.wheel-selector--buttons-right .wheel-selector-item-container {
  @apply mr-12;
}
.wheel-selector--buttons-left .wheel-selector-item-container {
  @apply ml-12;
}

.wheel-selector-hidden-select:focus + .wheel-selector-item-container {
  @apply shadow-outline;
}

.wheel-selector-hidden-select {
  @apply absolute;
  @apply appearance-none;
  @apply top-0;
  @apply w-24;
  @apply h-24;
  opacity: 0.01;
  z-index: 1;
}
.wheel-selector-hidden-select:focus {
  @apply outline-none;
}
.wheel-selector-hidden-select:not[disabled] {
  @apply cursor-pointer;
  touch-action: none;
}
.wheel-selector-hidden-select[disabled] ~ .wheel-selector-button-up,
.wheel-selector-hidden-select[disabled] ~ .wheel-selector-button-down {
  @apply cursor-default;
}
.wheel-selector--buttons-right .wheel-selector-hidden-select {
  @apply left-0;
}
.wheel-selector--buttons-left .wheel-selector-hidden-select {
  @apply right-0;
}

.wheel-selector-item {
  @apply absolute;
  @apply opacity-0;
  font-size: 1.75rem;
  transition: transform 250ms, opacity 250ms, color 250ms;
}

.wheel-selector-item--prev {
  transform: scale(0.4) translateY(-3.5rem);
}

.wheel-selector-item--prev-immediate {
  transform: scale(0.6) translateY(-2.6rem);
  @apply opacity-100;
  @apply text-teal-light;
}

.wheel-selector-item--selected {
  transform: scale(0.99);
  @apply opacity-100;
}

.wheel-selector-item--next-immediate {
  transform: scale(0.6) translateY(2.6rem);
  @apply opacity-100;
  @apply text-teal-light;
}

.wheel-selector-item--next {
  transform: scale(0.4) translateY(3.5rem);
}
