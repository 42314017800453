/**
 * MARKDOWN
 */

.markdown {
  @apply text-blue-darkest;
}

.markdown h2 {
  @apply text-teal-default;
  @apply font-semibold;
  @apply mb-3;
  @apply mt-10;
  @apply text-xl;
  @apply leading-snug;
}

.markdown h2:first-child {
  @apply mt-4;
}

.markdown h3 {
  @apply text-lg;
  @apply font-semibold;
  @apply mb-3;
  @apply mt-6;
}

.markdown p,
.markdown ol,
.markdown ul {
  @apply my-3;
}

.markdown ul {
  @apply list-disc;
}

.markdown ol {
  @apply list-decimal;
}

.markdown ul,
.markdown ol {
  @apply ml-6;
  @apply p-0;
  @apply text-left;
}

.markdown li {
  @apply my-2;
}

.markdown a[href]:not([class*="btn-"]) {
  @apply underline;
  @apply text-teal-default;
  @apply transition-colors;
  @apply duration-300;
}

.markdown a[href]:not([class*="btn-"]):not([class*="rounded-"]):hover {
  @apply text-teal-dark;
}

.markdown a[href]:not([class*="btn-"]):not([class*="rounded-"]):focus {
  @apply outline-none;
  @apply shadow-outline;
  @apply rounded;
}

.markdown hr {
  @apply my-5;
}

.markdown details[open] {
  @apply mb-8;
}
.markdown summary {
  @apply my-3;
  @apply font-semibold;
  @apply cursor-pointer;
  @apply rounded;
}
.markdown summary::-webkit-details-marker,
.markdown summary::marker {
  @apply text-teal-default;
}
.markdown summary:focus {
  @apply outline-none;
  @apply shadow-outline;
}

/**
 * Used in Elm views to display formatted text
 */

/* monospace font not wanted, because backticks are used for escaping user content only */
.markdown.markdown-elm-inline code {
  font-family: inherit;
}

/* no margin wanted / resetting (!) */
.markdown.markdown-elm-inline p {
  @apply my-0;
}

/**
 * Always use it like this:
 *
 *    > Text of the quote ...
 *    >
 *    > — Author
 */
.markdown blockquote {
  @apply my-5;
  @apply pl-5;
  @apply border-l-4;
  @apply border-blue-lightest;
}
.markdown blockquote > p:last-child {
  @apply text-sm;
}
.markdown blockquote > p:not(:last-child) {
  @apply font-serif;
  @apply italic;
  @apply text-xl;
}
