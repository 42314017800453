/**
 * PAGE
 *
 * This is a collection of page related classes. They are kind of like utilities
 * but in combination they can be seen as a component. The ".page" class is also
 * used by the navigation component to move the page's content to the right when
 * the navigation moves in.
 */

.page-wrapper {
  @apply w-full;
  @apply overflow-x-hidden;
}

.page {
  @apply relative;
  @apply text-blue-darkest;
  @apply bg-white;
}

.page-with-gradient .page {
  background-image: linear-gradient(
    90deg,
    theme("colors.teal-lightest-tint-15"),
    theme("colors.blue-lightest-tint-15")
  );
}

.page-min-h {
  /* full page */
  min-height: 100vh;
}
header + .page-min-h {
  /* full page - header height */
  min-height: calc(100vh - 4.5rem);
}
