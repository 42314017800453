/**
 * LINK, LINK-INSIDE
 *
 * Used for styling regular text links (.link) and text links where
 * some text inside is marked with a <u>-tag.
 */

.link,
.link-inside {
  @apply outline-none;
}

.link,
.link-inside u {
  @apply underline;
  @apply text-teal-default;
  @apply transition-colors;
  @apply duration-300;
}

.link:hover,
.link-inside u:hover {
  @apply text-teal-dark;
}

.link:focus,
.link-inside:focus u {
  @apply shadow-outline;
  @apply rounded;
}

