@tailwind base;
@tailwind components;
@tailwind utilities;

/*******************************************************************************
 * BASE STYLES
 */

@layer base {
  /* NOTE: font families are configured in ../../tailwind.config.js */
  @import "../fonts/exo.css";
  @import "../fonts/roboto-mono.css";

  html {
    /* https://github.com/jonaskuske/smoothscroll-anchor-polyfill#usage */
    --scroll-behavior: smooth;
    scroll-behavior: smooth;
  }

  /* for removing the highlight */
  body {
    -webkit-tap-highlight-color: transparent;
  }

  @screen sm {
    html {
      font-size: 115%;
    }
  }
  @screen md {
    html {
      font-size: 125%;
    }
  }
  @screen lg {
    html {
      font-size: 135%; /* NOTE: this scale will be used for the avatar images */
    }
  }

  /* hidden really means hidden */
  [hidden] {
    display: none !important;
  }

  /* let SVGs properly resize in its containers */
  svg {
    @apply w-full;
    @apply h-full;
  }
}

/*******************************************************************************
 * COMPONENTS
 */

@layer components {
  @import "./components/show-next-on-focus.css";
  @import "./components/page.css";
  @import "./components/speech-bubble.css";
  @import "./components/btn.css";
  @import "./components/icon-btn.css";
  @import "./components/language-recommendation.css";
  @import "./components/nav.css";
  @import "./components/wheel-selector.css";
  @import "./components/markdown.css";
  @import "./components/with-fancy-ol.css";
  @import "./components/hr-gradient.css";
  @import "./components/text-skeleton.css";
  @import "./components/footer-link.css";
  @import "./components/link.css";
  @import "./components/range-slider.css";
  @import "./components/img.css";
  @import "./components/lottie.css";
}

/*******************************************************************************
 * UTILITIES
 */

@layer utilities {
  /* show shadow outline when previous element has focus */
  *:focus + .focus-prev\:shadow-outline {
    @apply shadow-outline;
  }

  .disable-dbl-tap-zoom {
    touch-action: manipulation;
  }
}
